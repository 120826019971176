import * as React from 'react'
import {Parallax, ParallaxLayer} from '@react-spring/parallax'
import {useScroll, animated, useSpring} from "@react-spring/web";
import './App.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

function App() {
    const containerRef = React.useRef(null)

    const [textStyles, textApi] = useSpring(() => ({
        y: '100%',
    }))

    const {scrollYProgress} = useScroll()

    console.log(scrollYProgress)

    return (
        <div className="App">
            <Parallax pages={11} style={{top: '0', left: '0'}}>
                <ParallaxLayer
                    offset={0}
                    factor={12}
                    speed={0}
                    className="bg-neutral-900 bg-cover"
                ></ParallaxLayer>
                <ParallaxLayer // initial background
                    offset={0}
                    factor={2}
                    speed={0}
                    style={{
                        backgroundSize: 'cover',
                        backgroundPositionX: 'center',
                        backgroundPositionY: '50%',
                    }}
                    className="bg-gift"
                ></ParallaxLayer>
                <ParallaxLayer
                    offset={0.6}
                    speed={0.3}
                >
                    <div className="
                    flex
                    flex-col
                    ">
                        <div className="text-center">
                            <h2 className="
                            font-serif Kalnia
                            font-light
                            text-white
                            text-5xl
                            drop-shadow-md
                            xl:text-6xl
                            lg:text-4xl
                            ">
                                Scroll
                            </h2>
                        </div>
                        <FontAwesomeIcon icon={solid("chevron-down")} inverse size="4x" />
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={1.08}
                    speed={0.15}
                >
                    <div className="
                    text-left
                    translate-x-16
                    2xl:translate-x-96
                    xl:translate-x-80
                    lg:translate-x-60
                    md:translate-x-44
                    sm:translate-x-44
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-primary-gift
                        text-5xl
                        drop-shadow-md
                        xl:text-8xl
                        lg:text-6xl
                        ">
                            Jona
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={2}
                    speed={0.3}
                >
                    <div className="
                    text-center
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-5xl
                        xl:text-8xl
                        lg:text-6xl
                        ">
                            I wanted to give you a gift for christmas...
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={2.9}
                    speed={0.25}
                >
                    <div className="
                    text-center
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-5xl
                        xl:text-8xl
                        lg:text-6xl
                        ">
                            But you told me you don’t like it when people buy you gifts.
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={3.6}
                    speed={1}
                >
                    <div className="
                    text-center
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-5xl
                        xl:text-8xl
                        lg:text-6xl
                        ">
                            sooo
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={4}
                    speed={0.3}
                >
                    <div className="
                    text-center
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-5xl
                        xl:text-8xl
                        lg:text-6xl
                        ">
                            I decided to learn something new and make this instead :)
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={4.8}
                    speed={0.25}
                >
                    <div className="
                    text-center
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-5xl
                        xl:text-8xl
                        lg:text-6xl
                        ">
                            Its not thanksgiving anymore, but I’m feeling thankful-
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={5}
                    factor={1}
                    speed={0}
                    style={{
                        backgroundSize: 'cover',
                        backgroundPositionX: '65%'
                    }}
                    className="bg-s1"
                ></ParallaxLayer>
                <ParallaxLayer
                    offset={6}
                    factor={1}
                    speed={0}
                    style={{
                        backgroundSize: 'cover',
                        backgroundPositionX: '65%'
                    }}
                    className="bg-s2"
                ></ParallaxLayer>
                <ParallaxLayer
                    offset={5.5}
                    speed={0.02}
                >
                    <div className="flex justify-end">
                        <div className="h-100 w-72
                        -translate-x-28
                        2xl:-translate-x-96
                        xl:-translate-x-80
                        lg:-translate-x-72
                        md:-translate-x-52
                        sm:-translate-x-36
                        ">
                        <img className="w-full object-contain min-h-0" src={require('./images/coffee_back.png')}/>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={5.55}
                    speed={0.1}
                >
                    <div className="flex justify-end">
                        <div className="h-100 w-72
                        -translate-x-28
                        2xl:-translate-x-96
                        xl:-translate-x-80
                        lg:-translate-x-72
                        md:-translate-x-52
                        sm:-translate-x-36
                        ">
                            <img className="w-full object-contain min-h-0" src={require('./images/coffee_steam.png')}/>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={5.5}
                    speed={0.02}
                >
                    <div className="flex justify-end">
                        <div className="h-100 w-72
                        -translate-x-28
                        2xl:-translate-x-96
                        xl:-translate-x-80
                        lg:-translate-x-72
                        md:-translate-x-52
                        sm:-translate-x-36
                        ">
                        <img className="w-full object-contain min-h-0" src={require('./images/coffee_front.png')}/>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={5.5}
                    speed={0.25}
                >
                    <div className="
                    text-left
                    translate-x-14
                    2xl:translate-x-72
                    xl:translate-x-64
                    lg:translate-x-52
                    md:translate-x-40
                    sm:translate-x-44
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            For all the coffees
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={7}
                    factor={1}
                    speed={0}
                    style={{
                        backgroundSize: 'cover',
                        backgroundPositionX: '65%'
                    }}
                    className="bg-s3"
                ></ParallaxLayer>
                <ParallaxLayer
                    offset={6.2}
                    speed={0.1}
                >
                    <div className="
                    text-center
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            and
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={6.52}
                    speed={0.08}
                >
                    <div className="flex justify-normal">
                        <div className="h-100 w-72
                        translate-x-28
                        2xl:translate-x-96
                        xl:translate-x-80
                        lg:translate-x-72
                        md:translate-x-52
                        sm:translate-x-36
                        ">
                            <img className="w-full object-contain min-h-0" src={require('./images/candle_fire.png')}/>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={6.5}
                    speed={0.04}
                >
                    <div className="flex justify-normal">
                        <div className="h-100 w-72
                        translate-x-28
                        2xl:translate-x-96
                        xl:translate-x-80
                        lg:translate-x-72
                        md:translate-x-52
                        sm:translate-x-36
                        ">
                            <img className="w-full object-contain min-h-0" src={require('./images/candle.png')}/>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={6.9} // nice
                    speed={0.25}
                >
                    <div className="
                    text-right
                    -translate-x-14
                    2xl:-translate-x-72
                    xl:-translate-x-64
                    lg:-translate-x-52
                    md:-translate-x-40
                    sm:-translate-x-44
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            the fragrances
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={8}
                    factor={1}
                    speed={0}
                    style={{
                        backgroundSize: 'cover',
                        backgroundPositionX: '65%'
                    }}
                    className="bg-4"
                ></ParallaxLayer>
                <ParallaxLayer
                    offset={7.35}
                    speed={0.2}
                >
                    <div className="
                    text-center
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            Not to mention all the
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={7.5}
                    speed={0.08}
                >
                    <div className="flex justify-end">
                        <div className="h-100 w-72
                        -translate-x-28
                        2xl:-translate-x-96
                        xl:-translate-x-80
                        lg:-translate-x-72
                        md:-translate-x-52
                        sm:-translate-x-36
                        ">
                            <img className="w-full object-contain min-h-0" src={require('./images/cat_driving.png')}/>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={7.95}
                    speed={0.08}
                >
                    <div className="
                    text-center
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            giggles and laughs
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={8}
                    speed={0.04}
                >
                    <div className="flex justify-normal">
                        <div className="h-100 w-72
                        translate-x-28
                        2xl:translate-x-96
                        xl:translate-x-80
                        lg:translate-x-72
                        md:translate-x-52
                        sm:translate-x-36
                        ">
                            <img className="w-full object-contain min-h-0" src={require('./images/unamused_cat.png')}/>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={9}
                    factor={1}
                    speed={0}
                    style={{
                        backgroundSize: 'cover',
                        backgroundPositionX: '65%'
                    }}
                    className="bg-5"
                ></ParallaxLayer>
                <ParallaxLayer
                    offset={8.6}
                    speed={0.2}
                >
                    <div className="
                    text-center
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            And the Albanian lessons too
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={8.73}
                    speed={0.04}
                >
                    <div className="flex justify-center">
                        <div className="h-100 w-90">
                            <img className="w-full object-contain min-h-0" src={require('./images/eagle_bottom.png')}/>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={8.67}
                    speed={-0.06}
                >
                    <div className="flex justify-center">
                        <div className="h-100 w-90">
                            <img className="w-full object-contain min-h-0" src={require('./images/eagle_top.png')}/>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={9.25}
                    speed={0.15}
                >
                    <div className="
                    text-right
                    -translate-x-14
                    2xl:-translate-x-48
                    xl:-translate-x-24
                    lg:-translate-x-48
                    md:-translate-x-32
                    sm:-translate-x-44
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            djathtas
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={9.25}
                    speed={0.15}
                >
                    <div className="
                    text-left
                    translate-x-14
                    2xl:translate-x-48
                    xl:translate-x-24
                    lg:translate-x-48
                    md:translate-x-32
                    sm:translate-x-44
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            majtas
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={10}
                    factor={1}
                    speed={0}
                    style={{
                        backgroundSize: 'cover',
                        backgroundPositionX: '65%'
                    }}
                    className="bg-6_sky"
                ></ParallaxLayer>
                <ParallaxLayer
                    offset={10}
                    factor={1}
                    speed={0.15}
                    style={{
                        backgroundSize: 'cover',
                        backgroundPositionX: '65%'
                    }}
                    className="bg-6_moon"
                ></ParallaxLayer>
                <ParallaxLayer
                    offset={10.3}
                    speed={0.1}
                >
                    <div className="
                    text-left
                    translate-x-14
                    2xl:translate-x-48
                    xl:translate-x-24
                    lg:translate-x-48
                    md:translate-x-32
                    sm:translate-x-44
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-100
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            Merry Christmas!
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={10}
                    factor={1}
                    speed={0}
                    style={{
                        backgroundSize: 'cover',
                        backgroundPositionX: '65%'
                    }}
                    className="bg-6_mountain"
                ></ParallaxLayer>
                <ParallaxLayer
                    offset={9.9}
                    speed={0.1}
                >
                    <div className="
                    text-center
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            Thanks for all the memories
                        </h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={10.6}
                    speed={0}
                >
                    <div className="
                    text-right
                    -translate-x-14
                    2xl:-translate-x-48
                    2xl:translate-y-72
                    xl:-translate-x-24
                    xl:translate-y-72
                    lg:-translate-x-48
                    lg:translate-y-72
                    md:-translate-x-32
                    md:translate-y-72
                    sm:-translate-x-44
                    sm:translate-y-72
                    ">
                        <h2 className="
                        font-serif Kalnia
                        font-light
                        text-stone-300
                        text-3xl
                        xl:text-6xl
                        lg:text-4xl
                        ">
                            - Liam
                        </h2>
                    </div>
                </ParallaxLayer>
            </Parallax>
        </div>
    );
}

export default App;
